import styled from 'styled-components';

export const Container = styled.section`
  margin: 96px 24px 128px;
`;

export const Wrapper = styled.div`
  max-width: 80ch;

  margin: auto;

  h2 {
    color: ${({ theme }) => theme.colors.turtleGreen};
  }

  h3 {
    margin-bottom: 0;

    & + p {
      margin-top: 0;
    }
  }

  hr {
    margin: ${60 / 22}em 0;
  }
`;
