// vendors
import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

// components
import Layout from '@components/Layout';
import styled from 'styled-components';

// views
import SEO from '@src/components/SEO';
import HeroSection from '@src/views/AboutPageView/HeroSection';
import ContentSection from '@src/views/AboutPageView/ContentSection';

const Link = styled(GatsbyLink)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  text-decoration: none;

  :hover,
  :focus {
    text-decoration: underline;
  }
`;

const ProtectionPrivateInfoPage = () => {
  const langLinks = [
    { langKey: 'fr', href: '/protection-renseignements-prives' },
  ];

  return (
    <Layout localeLinks={langLinks}>
      <SEO
        langLinks={langLinks}
        title='Protection des renseignements privés et nom du responsable'
        description='Permafil inc. a pris les dispositions pour protéger les renseignements personnels de ses employés, collaborateurs, fournisseurs et utilisateurs de son site Web conformément à la Loi 25.'
      />

      <HeroSection>
        <h1>Protection of private information</h1>
      </HeroSection>

      <ContentSection>
        <p>
          <Link to='/en/about'>PERMAFIL INC.</Link> has taken steps to protect
          the personal information of its employees, collaborators, suppliers
          and users of this website in accordance with Law 25,{' '}
          <span
            css={`
              font-style: italic;
            `}
          >
            Act respecting the protecion of Personal Information in the private
            sector
          </span>
          .
        </p>

        <p>
          In facts, an inventory of sensitive information was made and,{' '}
          <Link to='/en/about'>PERMAFIL INC.</Link> has appointed a person
          responsible for the protection of personal information so that no harm
          is caused by the disclosure of this information without the prior
          authorization of the person concerned.
        </p>

        <p>
          This person ensures that all information is properly destroyed within
          the time limits prescribed by law, that the people having access to
          this information have signed confidentiality agreements, that the
          number of these people is restricted and that they aware of Law 25 on
          the protection of private information and that they will act
          accordingly. The files containing this information are under lock and
          key, and electronically, the company’s information is protected by
          firewalls and antiviruses.
        </p>

        <p>Person responsible: Francis Levesque</p>

        <p>
          Email:{' '}
          <a href='mailto:controleur@permafil.com' itemProp='email'>
            controleur@permafil.com
          </a>
        </p>
      </ContentSection>
    </Layout>
  );
};

export default ProtectionPrivateInfoPage;
